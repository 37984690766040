import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import CarItem from "../components/UI/CarItem";
import axios from "axios";
import { Spinner } from "reactstrap";

const CarListing = () => {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const response = await axios.get("/api/cars");
        setCars(response.data.filter((car) => car.visibilidad === true));
        setLoading(false);
      } catch (err) {
        console.error("Error al cargar coches:", err);
        setError("No se pudieron cargar los coches.");
        setLoading(false);
      }
    };
    fetchCars();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner
          style={{
            width: "4rem",
            height: "4rem",
            color: "#007bff",
          }}
        />
      </div>
    );
  }
  if (error) {
    return <p>{error}</p>;
  }

  return (
    <Helmet title="Cars">
      <CommonSection title="Coches a la venta" />

      <section>
        <Container>
          <Row>
            {cars.length > 0 ? (
              cars.map((item) => <CarItem item={item} key={item.id} />)
            ) : (
              <Col>
                <p>No hay coches disponibles en este momento.</p>
              </Col>
            )}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default CarListing;

import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../styles/EditCar.css";

const EditCar = () => {
    const [cars, setCars] = useState([]);
    const [selectedCar, setSelectedCar] = useState(null);
    const [formValues, setFormValues] = useState(null);
    const [files, setFiles] = useState([]);
    const [imagesToRemove, setImagesToRemove] = useState([]); // Almacena imágenes a eliminar
    const [message, setMessage] = useState(null);

    // Cargar coches al montar el componente
    useEffect(() => {
        const fetchCars = async () => {
            try {
                const response = await axios.get("/api/cars");
                setCars(response.data.filter((car) => car.visibilidad === true));
            } catch (err) {
                console.error("Error al cargar coches:", err);
            }
        };
        fetchCars();
    }, []);

    // Manejar selección de coche
    const handleSelectCar = (car) => {
        setSelectedCar(car);
        setFormValues({ ...car });
        setImagesToRemove([]); // Resetea imágenes a eliminar
    };

    // Manejar cambios en los campos del formulario
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues({
            ...formValues,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    // Manejar selección de imágenes nuevas
    const handleFileChange = (e) => {
        setFiles(e.target.files);
    };

    // Alternar estado de eliminación de imágenes actuales
    const toggleImageToRemove = (image) => {
        setImagesToRemove((prev) =>
            prev.includes(image) ? prev.filter((img) => img !== image) : [...prev, image]
        );
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
            
        // Normalizar imágenes actuales y obtener solo nombres de archivo
        const normalizedImages = selectedCar.imagenes.map((image) => image.split("/").pop());
      
        // Normalizar imágenes a eliminar
        const normalizedImagesToRemove = imagesToRemove.map((image) => image.split("/").pop());
      
        // Filtrar imágenes para excluir las que se quieren eliminar
        const filteredImages = normalizedImages.filter(
          (image) => !normalizedImagesToRemove.includes(image)
        );
      
        const formData = new FormData();
        for (const key in formValues) {
          formData.append(key, formValues[key]);
        }
      
        // Añadir nuevas imágenes al formulario
        Array.from(files).forEach((file) => {
          formData.append("imagenes", file);
        });
      
        // Añadir imágenes restantes al formulario
        filteredImages.forEach((image) => {
          formData.append("imagenes", image);
        });
      
        // Añadir imágenes a eliminar (normalizadas)
        formData.append("imagenesEliminar", JSON.stringify(normalizedImagesToRemove));
            
        try {
          const response = await axios.put(
            `/api/edit/${selectedCar.id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
      
          setMessage("Coche actualizado exitosamente!");
          console.log("Respuesta del servidor:", response.data);
      
          // Actualizar imágenes del coche seleccionado
          setSelectedCar((prev) => ({
            ...prev,
            imagenes: response.data.data.imagenes,
          }));
        } catch (err) {
          setMessage("Error al actualizar el coche. Inténtalo nuevamente.");
          console.error("Error:", err);
        }
      };
      

    return (
        <div className="edit-car-container">
            <h1>Editar Coche</h1>
            {message && <p className="message">{message}</p>}

            {/* Lista de coches */}
            <div className="car-list">
                <h2>Selecciona un coche</h2>
                <ul>
                    {cars.map((car) => (
                        <li
                            key={car.id}
                            onClick={() => handleSelectCar(car)}
                            className={selectedCar?.id === car.id ? "selected" : ""}
                        >
                            {car.modelo} - {car.marca}
                        </li>
                    ))}
                </ul>
            </div>

            {/* Formulario de edición */}
            {selectedCar && (
                <form onSubmit={handleSubmit} className="edit-car-form">
                    <h2>Editar Coche: {selectedCar.modelo}</h2>

                    {/* Campos del formulario */}
                    <div className="form-group">
                        <label htmlFor="modelo">Modelo</label>
                        <input
                            type="text"
                            id="modelo"
                            name="modelo"
                            value={formValues.modelo}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="precio">Precio</label>
                        <input
                            type="number"
                            id="precio"
                            name="precio"
                            value={formValues.precio}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="descripcion">Descripción</label>
                        <textarea
                            id="descripcion"
                            name="descripcion"
                            value={formValues.descripcion}
                            onChange={handleInputChange}
                        ></textarea>
                    </div>
                    <div className="form-group">
                        <label htmlFor="year">Año</label>
                        <input
                            type="text"
                            id="year"
                            name="year"
                            value={formValues.year}
                            onChange={handleInputChange}
                        />
                    </div>

                    {/* Imágenes actuales */}
                    <div className="form-group">
                        <label>Imágenes actuales</label>
                        <div className="current-images">
                            {selectedCar.imagenes.map((image, index) => (
                                <div key={index} className="image-item">
                                    <img
                                        src={`${image}`}
                                        alt={`Imagen ${index + 1}`}
                                    />
                                    <button
                                        type="button"
                                        onClick={() => toggleImageToRemove(image)}
                                        className={imagesToRemove.includes(image) ? "remove-selected" : ""}
                                    >
                                        {imagesToRemove.includes(image) ? "Restaurar" : "Eliminar"}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Nuevas imágenes */}
                    <div className="form-group">
                        <label htmlFor="imagenes">Añadir nuevas imágenes</label>
                        <input
                            type="file"
                            id="imagenes"
                            name="imagenes"
                            multiple
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                        {files.length > 0 && (
                            <div className="new-images">
                                {Array.from(files).map((file, index) => (
                                    <div key={index} className="image-item">
                                        <img src={URL.createObjectURL(file)} alt={`Nueva Imagen ${index + 1}`} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <button type="submit" className="submit-button">
                        Guardar Cambios
                    </button>
                </form>
            )}
        </div>
    );
};

export default EditCar;

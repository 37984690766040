import React, { useState } from "react";
import "../../styles/booking-form.css";
import { Form, FormGroup } from "reactstrap";
import emailjs from "emailjs-com";

const BookingForm = ({ car }) => {
  const [formData, setFormData] = useState({
    nombre: "",
    apellidos: "",
    email: "",
    telefono: "",
    direccion: "",
    mensaje: "",
  });
  const [message, setMessage] = useState("");

  const submitHandler = (event) => {
    event.preventDefault();

    emailjs
      .send(
        "service_fnk548r", // Service ID
        "template_9tu0ne7", // Template ID
        {
          tipo: "Solicitud de compra",
          nombre: formData.nombre,
          email: formData.email,
          telefono: formData.telefono,
          direccion: formData.direccion,
          mensaje: `${formData.mensaje}`,
          marca: car.marca,
          modelo: car.modelo,
          precio: car.precio,
        },
        "NI8oaM-9UouXK-kZL" // Public Key
      )
      .then(
        () => {
          setMessage("Solicitud enviada exitosamente. Nos pondremos en contacto contigo.");
          setFormData({
            nombre: "",
            apellidos: "",
            email: "",
            telefono: "",
            direccion: "",
            mensaje: "",
          });
        },
        (error) => {
          console.error("Error al enviar la solicitud:", error);
          setMessage("Hubo un error al enviar la solicitud. Inténtalo de nuevo.");
        }
      );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Form onSubmit={submitHandler}>
      {message && <p className="text-success">{message}</p>}
      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <input
          type="text"
          name="nombre"
          placeholder="Nombre"
          value={formData.nombre}
          onChange={handleInputChange}
          required
        />
      </FormGroup>
      <FormGroup className="booking__form d-inline-block ms-1 mb-4">
        <input
          type="text"
          name="apellidos"
          placeholder="Apellidos"
          value={formData.apellidos}
          onChange={handleInputChange}
          required
        />
      </FormGroup>
      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
      </FormGroup>
      <FormGroup className="booking__form d-inline-block ms-1 mb-4">
        <input
          type="text"
          name="telefono"
          placeholder="Número de teléfono"
          value={formData.telefono}
          onChange={handleInputChange}
          required
        />
      </FormGroup>
      <FormGroup className="booking__form d-inline-block me-4 mb-4 w-100">
        <input
          type="text"
          name="direccion"
          placeholder="Dirección"
          className="form-control w-100"
          value={formData.direccion}
          onChange={handleInputChange}
          required
        />
      </FormGroup>
      <FormGroup>
        <textarea
          rows={5}
          type="textarea"
          className="textarea"
          name="mensaje"
          placeholder="Cuéntanos más sobre tu compra"
          value={formData.mensaje}
          onChange={handleInputChange}
          required
        ></textarea>
      </FormGroup>
      <FormGroup>
        <button type="submit" className="w-50 car__item-btn car__btn-rent">
          <span className="text-white">Enviar</span>
        </button>
      </FormGroup>
      <span className="text-muted">* Te contactaremos para finalizar de gestionar tu compra</span>
    </Form>
  );
};

export default BookingForm;

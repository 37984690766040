import React, { useState } from "react";
import axios from "axios";
import "../../styles/AddCar.css";

const AddCar = () => {
  const [formValues, setFormValues] = useState({
    modelo: "",
    precio: "",
    descripcion: "",
    year: "",
    transmision: "",
    km: "",
    marca: "",
    visibilidad: true,
    puertas: "",
    plazas: "",
    tipo_gasolina: "",
    etiqueta: "",
    caballos: "",
  });
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState(null);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const renamedFiles = selectedFiles.map((file) => {
      const timestamp = Date.now(); // Generar un timestamp único
      const sanitizedFileName = file.name.replace(/\s+/g, "_"); // Reemplazar espacios con "_"
      const newFileName = `${formValues.modelo}_${timestamp}_${sanitizedFileName}`; // Agregar modelo y timestamp

      // Crear un nuevo archivo con el nombre modificado
      return new File([file], newFileName, { type: file.type });
    });

    setFiles(renamedFiles); // Guardar los archivos renombrados en el estado
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    for (const key in formValues) {
      formData.append(key, formValues[key]);
    }
    files.forEach((file) => {
      formData.append("imagenes", file);
    });

    try {
      const response = await axios.post("/api/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setMessage("Coche creado exitosamente!");
      console.log("Respuesta del servidor:", response.data);
    } catch (err) {
      setMessage("Error al crear el coche. Inténtalo nuevamente.");
      console.error("Error:", err);
    }
  };

  return (
    <div className="add-car-container">
      <h1>Crear Nuevo Coche</h1>
      {message && <p className="message">{message}</p>}
      <form onSubmit={handleSubmit} className="add-car-form">
        <div className="form-group">
          <label htmlFor="modelo">Modelo</label>
          <input
            type="text"
            id="modelo"
            name="modelo"
            value={formValues.modelo}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="precio">Precio</label>
          <input
            type="number"
            id="precio"
            name="precio"
            value={formValues.precio}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="descripcion">Descripción</label>
          <textarea
            id="descripcion"
            name="descripcion"
            value={formValues.descripcion}
            onChange={handleInputChange}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="year">Año</label>
          <input
            type="text"
            id="year"
            name="year"
            value={formValues.year}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="transmision">Transmisión</label>
          <input
            type="text"
            id="transmision"
            name="transmision"
            value={formValues.transmision}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="km">Kilometraje</label>
          <input
            type="number"
            id="km"
            name="km"
            value={formValues.km}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="marca">Marca</label>
          <input
            type="text"
            id="marca"
            name="marca"
            value={formValues.marca}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="puertas">Puertas</label>
          <input
            type="number"
            id="puertas"
            name="puertas"
            value={formValues.puertas}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="plazas">Plazas</label>
          <input
            type="number"
            id="plazas"
            name="plazas"
            value={formValues.plazas}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="tipo_gasolina">Tipo de carburante</label>
          <input
            type="text"
            id="tipo_gasolina"
            name="tipo_gasolina"
            value={formValues.tipo_gasolina}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="etiqueta">Etiqueta (A,B,C)</label>
          <input
            type="text"
            id="etiqueta"
            name="etiqueta"
            value={formValues.etiqueta}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="caballos">Caballos (cc)</label>
          <input
            type="text"
            id="caballos"
            name="caballos"
            value={formValues.caballos}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="imagenes">Imágenes</label>
          <input
            type="file"
            id="imagenes"
            name="imagenes"
            multiple
            accept="image/*"
            onChange={handleFileChange}
          />
        </div>
        <button type="submit" className="submit-button">
          Crear Coche
        </button>
      </form>
    </div>
  );
};

export default AddCar;

import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import { useParams } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import BookingForm from "../components/UI/BookingForm";
import { Spinner } from "reactstrap";

const CarDetails = ({ cars }) => {
  const { marca, modelo } = useParams();
  const [selectedCar, setSelectedCar] = useState(null);

  useEffect(() => {
    const car = cars.find(
      (item) => item.marca === marca && item.modelo === modelo
    );
    setSelectedCar(car);
    window.scrollTo(0, 0);
  }, [marca, modelo, cars]);

  if (!selectedCar) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner
          style={{
            width: "4rem",
            height: "4rem",
            color: "#007bff",
          }}
        />
      </div>
    );
  }

  return (
    <Helmet title={`${selectedCar.marca} ${selectedCar.modelo}`}>
      <section>
        <Container>
          <Row>
            <Col lg="6">
              <Carousel>
                {selectedCar.imagenes.map((image, index) => (
                  <Carousel.Item key={index}>
                    <img className="d-block w-100" src={image} alt={`Imagen ${index + 1}`} />
                  </Carousel.Item>
                ))}
              </Carousel>
            </Col>

            <Col lg="6">
              <div className="car__info">
                <h2 className="section__title">{selectedCar.modelo}</h2>
                <div className="rent__price mb-4 mt-3">
                  <h6 className="fw-bold fs-4">{selectedCar.precio} €</h6>
                </div>
                <p className="section__description">{selectedCar.descripcion}</p>

                {/* Información adicional en filas */}
                <div className="mt-4">
                  <Row className="mb-3">
                    <Col lg="6" md="6" sm="12">
                      <span className="d-flex align-items-center gap-1 section__description">
                        <i className="ri-roadster-line" style={{ color: "#f9a826" }}></i> Marca: {selectedCar.marca}
                      </span>
                    </Col>
                    <Col lg="6" md="6" sm="12">
                      <span className="d-flex align-items-center gap-1 section__description">
                        <i className="ri-calendar-line" style={{ color: "#f9a826" }}></i> Año: {selectedCar.year}
                      </span>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col lg="6" md="6" sm="12">
                      <span className="d-flex align-items-center gap-1 section__description">
                        <i className="ri-settings-2-line" style={{ color: "#f9a826" }}></i> Transmisión: {selectedCar.transmision}
                      </span>
                    </Col>
                    <Col lg="6" md="6" sm="12">
                      <span className="d-flex align-items-center gap-1 section__description">
                        <i className="ri-dashboard-line" style={{ color: "#f9a826" }}></i> Kilometraje: {selectedCar.km} KM
                      </span>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col lg="6" md="6" sm="12">
                      <span className="d-flex align-items-center gap-1 section__description">
                        <i className="ri-wheelchair-line" style={{ color: "#f9a826" }}></i> Plazas: {selectedCar.plazas}
                      </span>
                    </Col>
                    <Col lg="6" md="6" sm="12">
                      <span className="d-flex align-items-center gap-1 section__description">
                        <i className="ri-building-2-line" style={{ color: "#f9a826" }}></i> Puertas: {selectedCar.puertas}
                      </span>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col lg="6" md="6" sm="12">
                      <span className="d-flex align-items-center gap-1 section__description">
                        <i className="ri-gas-station-line" style={{ color: "#f9a826" }}></i> Combustible: {selectedCar.tipo_gasolina}
                      </span>
                    </Col>
                    <Col lg="6" md="6" sm="12">
                      <span className="d-flex align-items-center gap-1 section__description">
                        <i className="ri-roadster-line" style={{ color: "#f9a826" }}></i> Caballos: {selectedCar.caballos} CV
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6" md="6" sm="12">
                      <span className="d-flex align-items-center gap-1 section__description">
                        <i className="ri-checkbox-line" style={{ color: "#f9a826" }}></i> Etiqueta: {selectedCar.etiqueta}
                      </span>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg="7" className="mt-5 mx-auto">
              <div className="booking-info mt-5 text-center">
                <h5 className="mb-4 fw-bold">Información de compra</h5>
                <BookingForm car={selectedCar} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default CarDetails;

import React from "react";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/footer.css";

const quickLinks = [
  {
    path: "/about",
    display: "Sobre nosotros",
  },

  {
    path: "#",
    display: "Política de privacidad",
  },

  {
    path: "/cars",
    display: "Coches a la venta",
  },

  {
    path: "/contact",
    display: "Contacto",
  },
];

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="4" md="4" sm="12">
            <div className="logo footer__logo">
              <h1>
                <Link to="/home" className=" d-flex align-items-center gap-2">
                  <i class="ri-car-line"></i>
                  <span>
                    Dedea Motor
                  </span>
                </Link>
              </h1>
            </div>
            <p className="footer__logo-content">
              Encuentra el coche de tus sueños al mejor precio, con toda la garantía de calidad y sin complicaciones. En nuestro concesionario, no solo vendemos coches, ¡vendemos tranquilidad! Nos encargamos de todas las gestiones necesarias para que puedas conducir tu nuevo coche en el menor tiempo posible, y siempre con la seguridad de que has tomado la mejor decisión.
            </p>
          </Col>

          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">Enlaces rápidos</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>

          <Col lg="3" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">Localización</h5>
              <p className="office__info">Colmenar de Oreja, Madrid, España</p>
              <p className="office__info">Teléfono: +34 642 816 700</p>

              <p className="office__info">Email: dedeamotor.services@gmail.com</p>

              <p className="office__info">Disponibilidad: 08am - 20pm</p>
            </div>
          </Col>

          <Col lg="3" md="4" sm="12">
            <div className="mb-4">
              <h5 className="footer__link-title">Entérate de las novedades</h5>
              <p className="section__description">Suscríbete a nuestra newsletter</p>
              <div className="newsletter">
                <input type="email" placeholder="Email" />
                <span>
                  <i class="ri-send-plane-line"></i>
                </span>
              </div>
            </div>
          </Col>

          <Col lg="12">
            <div className="footer__bottom">
              <p className="section__description d-flex align-items-center justify-content-center gap-1 pt-4">
                <i class="ri-copyright-line"></i>Copyright {year}, Developed by
                Alejandro Vindel. All rights reserved.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;

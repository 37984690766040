import React from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/index.css";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="home-container">
      <h1 className="home-title">Gestión de Coches</h1>
      <div className="card-container">
        {/* Carta para añadir coches */}
        <div className="card" onClick={() => navigate("/private/addCar")}>
          <h2>Añadir Coche</h2>
          <p>Agrega un nuevo coche al inventario.</p>
        </div>

        {/* Carta para editar coches */}
        <div className="card" onClick={() => navigate("/private/editCar")}>
          <h2>Editar Coche</h2>
          <p>Edita un coche del inventario.</p>
        </div>

        {/* Carta para ocultar o marcar como vendido */}
        <div className="card" onClick={() => navigate("/private/hideCar")}>
          <h2>Ocultar o Vender</h2>
          <p>Oculta un coche o márcalo como vendido.</p>
        </div>

        {/* Carta para volver a mostrar un coche */}
        <div className="card" onClick={() => navigate("/private/showCar")}>
          <h2>Volver a poner a la venta</h2>
          <p>Vuelve a poner un coche que ya hayas marcado como vendido.</p>
        </div>
      </div>
    </div>
  );
};

export default Home;

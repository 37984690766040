import React, {useState,useEffect} from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import CarListing from "../pages/CarListing";
import CarDetails from "../pages/CarDetails";
import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";
import NotFound from "../pages/NotFound";
import Contact from "../pages/Contact";
import Login from "../pages/private/Login";
import ProtectedRoute from "../pages/private/ProtectedRoute";
import Index from "../pages/private/Index";
import AddCar from "../pages/private/AddCar";
import EditCar from "../pages/private/EditCar";
import HideCar from "../pages/private/HideCar";
import ShowCar from "../pages/private/ShowCar";
import axios from "axios";

const Routers = () => {

  const [cars, setCars] = useState([]);

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const response = await axios.get("/api/cars");
        setCars(response.data); // Carga todos los coches
      } catch (err) {
        console.error("Error al cargar coches:", err);
      }
    };
    fetchCars();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home cars={cars} />} />
      <Route path="/about" element={<About />} />
      <Route path="/cars" element={<CarListing />} />
      <Route path="/cars/:marca/:modelo" element={<CarDetails cars={cars} />} />
      <Route path="/blogs" element={<Blog />} />
      <Route path="/blogs/:slug" element={<BlogDetails />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/private/login" element={<Login />} />
      <Route path="/private/index" element={<ProtectedRoute><Index /></ProtectedRoute>} />
      <Route path="/private/addCar" element={<ProtectedRoute><AddCar /></ProtectedRoute>} />
      <Route path="/private/editCar" element={<ProtectedRoute><EditCar /></ProtectedRoute>} />
      <Route path="/private/hideCar" element={<ProtectedRoute><HideCar /></ProtectedRoute>} />
      <Route path="/private/showCar" element={<ProtectedRoute><ShowCar /></ProtectedRoute>} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routers;

import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../styles/HideCar.css";

const HideCar = () => {
  const [cars, setCars] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);
  const [message, setMessage] = useState(null);

  // Cargar coches al montar el componente
  useEffect(() => {
    const fetchCars = async () => {
      try {
        const response = await axios.get("/api/cars");
        setCars(response.data.filter((car) => car.visibilidad === true));
      } catch (err) {
        console.error("Error al cargar coches:", err);
      }
    };
    fetchCars();
  }, []);

  // Manejar selección de coche
  const handleSelectCar = (car) => {
    setSelectedCar(car);
    setMessage(null); // Limpiar mensajes anteriores
  };

  // Manejar envío para marcar como vendido
  const handleMarkAsSold = async () => {
    if (!selectedCar) {
      setMessage("Por favor, selecciona un coche.");
      return;
    }

    try {
      const response = await axios.put(
        `/api/cars/${selectedCar.id}/visibility`,
        { visibilidad: false } // Actualiza visibilidad a false
      );
      setMessage(`El coche "${selectedCar.modelo}" se ha marcado como vendido.`);
      console.log("Respuesta del servidor:", response.data);

      // Actualizar la lista de coches localmente
      setCars((prevCars) =>
        prevCars.map((car) =>
          car.id === selectedCar.id ? { ...car, visibilidad: false } : car
        )
      );

      setSelectedCar(null); // Limpiar selección después de actualizar
    } catch (err) {
      setMessage("Error al marcar el coche como vendido. Inténtalo nuevamente.");
      console.error("Error:", err);
    }
  };

  return (
    <div className="mark-as-sold-container">
      <h1>Marcar Coche como Vendido</h1>
      {message && <p className="message">{message}</p>}

      {/* Lista de coches */}
      <div className="car-list">
        <h2>Selecciona un coche</h2>
        <ul>
          {cars.map((car) => (
            <li
              key={car.id}
              onClick={() => handleSelectCar(car)}
              className={selectedCar?.id === car.id ? "selected" : ""}
            >
              {car.modelo} - {car.marca}{" "}
              {!car.visibilidad && <span className="sold-tag">Vendido</span>}
            </li>
          ))}
        </ul>
      </div>

      {/* Formulario para marcar como vendido */}
      {selectedCar && (
        <div className="mark-as-sold-form">
          <h2>Coche Seleccionado: {selectedCar.modelo}</h2>
          <p>¿Quieres marcar este coche como vendido?</p>
          <button onClick={handleMarkAsSold} className="mark-as-sold-button">
            Marcar como Vendido
          </button>
        </div>
      )}
    </div>
  );
};

export default HideCar;

import { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";

const useLogin = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const login = async ({ username, password }) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post('/api/login', {
        username,
        password,
      });
      Cookies.set('token', response.data.token);
      console.log(response);
      navigate("/private/index");
    } catch (err) {
      console.error('Error en el inicio de sesión:', err);
      setError('Usuario o contraseña incorrectos');
    } finally {
      setIsLoading(false);
    }
  };

  return { login, isLoading, error };
};

export default useLogin;

import React, { useState } from "react";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";
import emailjs from "emailjs-com";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import { Link } from "react-router-dom";

import "../styles/contact.css";

const socialLinks = [
  {
    url: "https://www.instagram.com/dedea.motor/",
    icon: "ri-instagram-line",
  },
];

const Contact = () => {
  const [formData, setFormData] = useState({
    nombre: "",
    email: "",
    consulta: "",
  });
  const [message, setMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const submitHandler = (event) => {
    event.preventDefault();

    emailjs
      .send(
        "service_fnk548r", // Service ID
        "template_9tu0ne7", // Template ID
        {
          tipo: "Consulta",
          nombre: formData.nombre,
          email: formData.email,
          consulta: formData.consulta,
          telefono: "",
          direccion: "",
          mensaje: formData.consulta,
          marca: "",
          modelo: "",
          precio: "",
        },
        "NI8oaM-9UouXK-kZL" // Public Key
      )
      .then(
        () => {
          setMessage("Consulta enviada exitosamente. Nos pondremos en contacto contigo.");
          setFormData({
            nombre: "",
            email: "",
            consulta: "",
          });
        },
        (error) => {
          console.error("Error al enviar la consulta:", error);
          setMessage("Hubo un error al enviar la consulta. Inténtalo de nuevo.");
        }
      );
  };

  return (
    <Helmet title="Contacto">
      <CommonSection title="Contacto" />
      <section>
        <Container>
          <Row>
            <Col lg="7" md="7">
              <h6 className="fw-bold mb-4">Contacta con nosotros</h6>

              <Form onSubmit={submitHandler}>
                {message && <p className="text-success">{message}</p>}
                <FormGroup className="contact__form">
                  <Input
                    name="nombre"
                    placeholder="Nombre completo"
                    type="text"
                    value={formData.nombre}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
                <FormGroup className="contact__form">
                  <Input
                    name="email"
                    placeholder="Email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
                <FormGroup className="contact__form">
                  <textarea
                    name="consulta"
                    rows="5"
                    placeholder="Consulta"
                    className="textarea"
                    value={formData.consulta}
                    onChange={handleInputChange}
                    required
                  ></textarea>
                </FormGroup>

                <button className="contact__btn" type="submit">
                  Enviar
                </button>
              </Form>
            </Col>

            <Col lg="5" md="5">
              <div className="contact__info">
                <h6 className="fw-bold">Contacto de información</h6>
                <p className="section__description mb-0">Colmenar de Oreja, Madrid, España</p>
                <div className="d-flex align-items-center gap-2">
                  <h6 className="fs-6 mb-0">Teléfono:</h6>
                  <p className="section__description mb-0">+34 642 816 700</p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <h6 className="mb-0 fs-6">Email:</h6>
                  <p className="section__description mb-0">dedeamotor.services@gmail.com</p>
                </div>
                <h6 className="fw-bold mt-4">Síguenos</h6>
                <div className="d-flex align-items-center gap-4 mt-3">
                  {socialLinks.map((item, index) => (
                    <Link to={item.url} key={index} className="social__link-icon">
                      <i className={item.icon}></i>
                    </Link>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Contact;

import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../styles/ShowCar.css";

const ShowCar = () => {
  const [cars, setCars] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);
  const [message, setMessage] = useState(null);

  // Cargar coches al montar el componente
  useEffect(() => {
    const fetchCars = async () => {
      try {
        const response = await axios.get("/api/cars"); // Asegúrate de que esta ruta incluya todos los coches, incluso los no visibles
        setCars(response.data);
      } catch (err) {
        console.error("Error al cargar coches:", err);
      }
    };
    fetchCars();
  }, []);

  // Manejar selección de coche
  const handleSelectCar = (car) => {
    setSelectedCar(car);
    setMessage(null); // Limpiar mensajes anteriores
  };

  // Manejar envío para volver a poner a la venta
  const handleMarkAsAvailable = async () => {
    if (!selectedCar) {
      setMessage("Por favor, selecciona un coche.");
      return;
    }

    try {
      const response = await axios.put(
        `/api/cars/${selectedCar.id}/visibility`,
        { visibilidad: true } // Actualiza visibilidad a true
      );
      setMessage(`El coche "${selectedCar.modelo}" ahora está a la venta.`);
      console.log("Respuesta del servidor:", response.data);

      // Actualizar la lista de coches localmente
      setCars((prevCars) =>
        prevCars.map((car) =>
          car.id === selectedCar.id ? { ...car, visibilidad: true } : car
        )
      );

      setSelectedCar(null); // Limpiar selección después de actualizar
    } catch (err) {
      setMessage("Error al actualizar el estado del coche. Inténtalo nuevamente.");
      console.error("Error:", err);
    }
  };

  return (
    <div className="show-car-container">
      <h1>Volver a Poner a la Venta</h1>
      {message && <p className="message">{message}</p>}

      {/* Lista de coches */}
      <div className="car-list">
        <h2>Selecciona un coche</h2>
        <ul>
          {cars
            .filter((car) => !car.visibilidad) // Solo mostrar coches marcados como no visibles
            .map((car) => (
              <li
                key={car.id}
                onClick={() => handleSelectCar(car)}
                className={selectedCar?.id === car.id ? "selected" : ""}
              >
                {car.modelo} - {car.marca}{" "}
                {!car.visibilidad && <span className="sold-tag">Vendido</span>}
              </li>
            ))}
        </ul>
      </div>

      {/* Formulario para volver a poner a la venta */}
      {selectedCar && (
        <div className="show-car-form">
          <h2>Coche Seleccionado: {selectedCar.modelo}</h2>
          <p>¿Quieres volver a poner este coche a la venta?</p>
          <button
            onClick={handleMarkAsAvailable}
            className="mark-as-available-button"
          >
            Volver a Poner a la Venta
          </button>
        </div>
      )}
    </div>
  );
};

export default ShowCar;

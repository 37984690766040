import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import AboutSection from "../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";
import BecomeDriverSection from "../components/UI/BecomeDriverSection";

import driveImg from "../assets/all-images/drive.jpg";
import OurMembers from "../components/UI/OurMembers";
import "../styles/about.css";

const About = () => {
  return (
    <Helmet title="About">
      <CommonSection title="Sobre nosotros" />
      <AboutSection aboutClass="aboutPage" />

      <section className="about__page-section">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={driveImg} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">
                  Estamos comprometidos con la calidad
                </h2>

                <p className="section__description">
                  Nuestro compromiso con la calidad va más allá de las palabras. Cada vehículo que ofrecemos ha pasado por rigurosos controles y revisiones técnicas para asegurar que cumpla con los más altos estándares de seguridad y rendimiento. Nos esforzamos por brindarte una experiencia de compra que combine confianza, tranquilidad y satisfacción total.
                </p>

                <p className="section__description">
                  Con nosotros, tienes la garantía de estar invirtiendo en un coche fiable y de calidad, sin sorpresas. Nos encargamos de todos los detalles, desde la revisión inicial hasta la entrega final, para que puedas disfrutar de tu nuevo coche con total tranquilidad. Nuestro equipo de profesionales está a tu disposición para asesorarte y acompañarte en cada paso del proceso.
                </p>


                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i class="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">¿Necesitas ayuda?</h6>
                    <h4>+34 642 816 700</h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

    </Helmet>
  );
};

export default About;

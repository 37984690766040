import React from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/car-item.css";

const CarItem = (props) => {
  const { imagenes, modelo, marca, transmision, km, precio } = props.item;

  // Usar la primera imagen del array como la imagen principal
  const imgUrl = imagenes.length > 0 ? imagenes[0] : "default-car-image.jpg";

  return (
    <Col lg="4" md="4" sm="6" className="mb-5">
      <div className="car__item">
        <div className="car__img">
          <img src={imgUrl} alt={marca} className="w-100" />
        </div>

        <div className="car__item-content mt-4">
          <h4 className="section__title text-center">{marca}</h4>
          <h6 className="rent__price text-center mt-3">
            ${precio} <span>USD</span>
          </h6>

          <div className="car__item-info d-flex align-items-center justify-content-between mt-3 mb-4">
            <span className="d-flex align-items-center gap-1">
              <i className="ri-car-line"></i> {modelo}
            </span>
            <span className="d-flex align-items-center gap-1">
              <i className="ri-settings-2-line"></i> {transmision}
            </span>
            <span className="d-flex align-items-center gap-1">
              <i className="ri-dashboard-line"></i> {km} km
            </span>
          </div>

          <button className="w-50 car__item-btn car__btn-rent">
            <Link to={`/cars/${marca}/${modelo}`}>Compra</Link>
          </button>

          <button className="w-50 car__item-btn car__btn-details">
            <Link to={`/cars/${marca}/${modelo}`}>Detalles</Link>
          </button>
        </div>
      </div>
    </Col>
  );
};

export default CarItem;
